import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout/index";
import SEO from "../components/Seo";

const PrivacyWrapper = styled.section`
  background-color: ${(props) => props.theme.colors.mainBG};

  .inner-wrapper {
    h2 {
      font-weight: bold;
    }
    h3 {
      font-size: 1.5rem;
    }
    ul {
      li {
        list-style: disc;
        margin-left: 40px;
        padding-left: 10px;
        margin-bottom: 0.5rem;
      }
      .sub-ul {
        margin-left: 60px;

        .sub-list {
          list-style: circle;
          padding-left: 10px;
          margin-bottom: 0.5rem;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      ul {
        li {
          margin-left: 20px;
        }
        .sub-ul {
          margin-left: 40px;
        }
      }
    }
    @media only screen and (max-width: 425px) {
      ul {
        .sub-ul {
          margin-left: 30px;
        }
      }
    }
    @media only screen and (max-width: 320px) {
      ul {
        .sub-ul {
          margin-left: 15px;
        }
      }
    }
  }  
  p {
    margin-bottom: 0.8rem;
  }

  .last-block {
    margin-bottom: 1.5rem;
    }
  }
`;

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <PrivacyWrapper>
        <div className="inner-wrapper">
          <h1>Privacy Policy</h1>
          <p className="last-block">
            This Privacy Policy applies to madebyHaT collection and use of personally identifying information that
            madebyHaT may gather when you contact us, visit our website, or use the services of madebyHaT. By accessing,
            or using madebyHaT’s website/services, you expressly consent to the collection, processing and use of your
            personal information according to this Privacy Policy.{" "}
          </p>
          <h2 className="last-block">What information madebyHaT collects</h2>

          <h3>Personal Information</h3>
          <p className="last-block">
            madebyHaT collects personal information including your name and email address. This information collected
            will be sent to madebyHaT and may be used by madebyHaT without restriction within its normal operation. This
            information will NOT be shared with third party organisations unless we are obliged to by the relevant law.
          </p>

          <h3>Cookies and Other Technologies</h3>
          <p>
            madebyHaT uses “cookies” and other technologies to collect data that enables us to better understand and
            improve the usability, performance and effectiveness of our website. Cookies are files sent to your browser
            and stored on your computer. If you do not want madebyHaT to send cookies to your browser, you can set your
            browser options to reject cookies or notify you when a website tries to put a cookie into your browser
            software. Rejecting cookies may affect your ability to use some features of the madebyHaT website.
          </p>
          <p className="last-block">
            madebyHaT gathers general information and uses Google Analytics to analyse visits to our website, and your
            computer’s interaction with madebyHaT uses this information to improve our services, understand website
            traffic and the use of downloads from our website. We are always seeking to improve our online presence to
            provide the best online experience possible for our customers.
          </p>

          <h3>How madebyHaT Uses Information</h3>
          <p>
            madebyHaT will not sell your personal information to third parties. If you provide us with your email
            address, madebyHaT may, from time to time, share with you information about other products and services that
            we think you may find to be of interest. If you wish to change the types of communications you receive from
            us, you may do so by emailing your request via the contact page.
          </p>
          <p>
            Please note that opting-out of receiving promotional email will not affect receipt of service-related,
            transactional, or legal communication via email in accordance with the Terms. madebyHaT will not disclose
            your personal information to third parties unless disclosure is necessary to comply with relevant law of the
            land and/or below:
          </p>
          <ul>
            <li>We use and may disclose your personal information in order to:</li>
            <ul className="sub-ul">
              <li className="sub-list">Provide services to you;</li>
              <li className="sub-list">Research, develop, administer, protect and improve our services;</li>
              <li className="sub-list">Check your credit worthiness with a Credit Reporting Agency; and</li>
              <li className="sub-list">
                Comply with a legislative instrument such as a request for information by a regulatory body or a Court
                Order.
              </li>
            </ul>
            <li>
              Excluding the requirements in Clause 1 above, we will not share any information you give to us to any
              other entity without your express permission.
            </li>
            <li>
              Customers may request access to personal information collected by madebyHaT in relation to themselves and
              the use of their account. We work to ensure these details are always correct and up-to-date and will amend
              any inaccuracies or make changes to details upon request.
            </li>
            <li>
              By law, we must retain some customer information for a minimum of five years. However, we do not retain
              clients’ personal information longer than is necessary for the purposes of compliance with the law and
              routine administration.
            </li>
            <li>
              If madebyHaT is ever sold to another organisation, the new owners will also be required to comply with
              this privacy policy.
            </li>
            <li>
              We keep customer information in a controlled facility, secured against unauthorised access. Proof is
              always required before information is released to any person, including the customer.
            </li>
            <li>
              We are constantly improving and enhancing our services and may update this privacy policy from time to
              time. Any changes to the policy will be reflected by an updated page on our website.
            </li>
            <li className="last-block">
              If you have any queries or comments concerning our privacy policy, please contact us.{" "}
            </li>
          </ul>

          <h3>Personal Identifiable Information</h3>
          <p className="last-block">
            madebyHaT has committed to handling personally identifiable information in accordance with all currently
            applicable legislation.
          </p>

          <h3>Acceptance of terms</h3>
          <p className="last-block">
            If madebyHaT updates or changes this Privacy Policy, the changes will be made on this page. Your continued
            use of the website following the posting of changes will mean you accept those changes.
          </p>
        </div>
      </PrivacyWrapper>
    </Layout>
  );
};

export default Privacy;
